export const attractionsSelector = (state) => state.appState.Attractions;
export const getAttractionSelector = (attractionID) => (state) => state.appState.Attractions[attractionID];
export const theParksSelector = (state) => state.appState.TheParks;
export const getParkSelector = (pid) => (state) => state.appState.Parks[pid];
export const getSavedParksSelector = (state) => state.appState.SavedParks;
export const homeDataSelector = (state) => state.appState.Home;
export const hiwDataSelector = (state) => state.appState.HIW;
export const faqDataSelector = (state) => state.appState.FAQ.faqs;
export const hasFaqSelector = (state) => state.appState.FAQ;
export const getConfigSelector = (state) => state.appState.config;
