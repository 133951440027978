import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../Components/Loading';
import { addHiwData } from '../Redux/actions';
import { hiwDataSelector } from '../Redux/selectors';

function WithHIWsData(props) {
  const [hasData, setHasData] = useState(false);
  const dispatch = useDispatch();
  const hasHIW = useSelector(hiwDataSelector);

  useEffect(() => {
    const controller = new AbortController();
    if (Object.keys(hasHIW).length === 0) {
      fetch('/json/content/howItWorks.json', { signal: controller.signal })
        .then((response) => {
          if (!response.ok) throw new Error('HTTP error, status = ' + response.status);
          return response.json();
        })
        .then((json) => {
          addHiwData(dispatch, json);
        })
        .catch((error) => {
          console.error('Error: ' + error.message);
        })
        .finally(() => {
          setHasData(true);
        });
    } else {
      setHasData(true);
    }
    return () => {
      controller.abort();
    };
  }, [hasHIW, dispatch]);

  return hasData ? props.children : <Loading />;
}

export default WithHIWsData;
