export const fA = {
  0: ['^', 'Amusements'],
  1: ['T', 'Bar(s)'],
  2: ['R', 'Beach within 400 yards'],
  3: ['--', 'Cafe NOT DISPLAYED'],
  4: ['k', 'Kids Entertainment'],
  5: ['_', 'Crazy Golf/Putting'],
  6: ['c', 'Facilities for Partially Disabled'],
  7: ['--', 'Disco NOT DISPLAYED'],
  8: ['`', 'Golf on Park'],
  9: ['a', 'Gym'],
  10: ['W', 'Indoor Pool'],
  11: ['--', 'Jacuzzi / Spa NOT DISPLAYED'],
  12: ['Z', 'Lazy River Ride'],
  13: [']', 'Live Entertainment'],
  14: ['X', 'Outdoor Pool'],
  15: ['S', 'Restaurant/Café'],
  16: ['Y', 'Spa Facilities'],
  17: ['V', 'Shop/Supermarket'],
  18: ['--', 'Snooker / Pool NOT DISPLAYED'],
  19: ['[', 'Space Bowl'],
  20: ['--', 'Supermarket NOT DISPLAYED'],
  21: ['b', 'Tennis'],
  22: ['d', 'Dogs allowed in dog-friendly accommodation (additional charge)'],
  23: ['U', 'Takeaway'],
  24: ['g', 'Free food and drinks vouchers on selected midweek breaks (excludes school/bank holidays)'],
};
export const aA = {
  0: ['Q', 'Apartments'],
  1: ['--', 'Bungalows'],
  2: ['N', 'Caravan Homes'],
  3: ['O', 'Chalets/Lodges'],
  4: ['P', 'Cottages/Villas/Bungalows'],
  5: ['O', 'Lodges'],
  6: ['f', 'Tents'],
  7: ['P', 'Villas'],
  8: ['e', 'Upgrades available'],
  9: ['h', 'New this year'],
};

export const zA = [
  '--',
  '--',
  '--',
  '--',
  '--',
  '--',
  'Db0',
  'Db1',
  'Db2',
  'Sc1',
  'Sc2',
  'Sc3',
  'Sc4',
  'Sc5',
  'We1',
  'We2',
  'We3',
  'We4',
  'We5',
  'En1',
  'En2',
  'En3',
  'En4',
  'En5',
];

// use this with the React profiler
export const onRenderCallback = (id, phase, actualDuration) => {
  const time = (actualDuration / 1000).toFixed(2)
  let emoji = '🤩';
  if (time > 0.2) emoji = '😬';
  if (time > 0.5) emoji = '🤮';
  console.info(
    `The ${id} component took %c${time}s%c to ${phase} ${emoji}`,
    'color: #9c0; background: #000; padding: 2px 5px; border-radius: 5px',
    '',
  );
};

export const padStr = (s, l, c = '0') => {
  while (s.length < l) s = c + s;
  return s;
};

export const ParksSliderSettings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
