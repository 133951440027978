import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import svgTop from '../Assets/images/404-top.svg';
import svgBot from '../Assets/images/404-bottom.svg';
import { Helmet } from 'react-helmet-async';

function NotFound() {
  return (
    <>
      <Helmet>
        <title>Page not found</title>
      </Helmet>
      <Container className="d-flex flex-column flex-grow-1 ">
        <Row>
          <Col className="text-right">
            <img src={svgTop} style={{ maxWidth: '25vh' }} alt="404 Top" />
          </Col>
        </Row>
        <Row className=" d-flex flex-grow-1 align-items-center">
          <Col className="text-center">
            <h1 style={{ fontSize: '15vh', color: 'white', textShadow: '0.25rem 0.25rem 0.5rem #ddd' }}>404</h1>
            <p>The page you are looking for doesn&apos;t exist or was removed.</p>
            <Link to="/">Return to home page</Link>
            <div className="mb-2" />
            <Link to="/parks">Return to parks page</Link>
          </Col>
        </Row>
        <Row className="mt-auto">
          <Col className="text-left">
            <img src={svgBot} style={{ maxWidth: '25vh' }} alt="404 Bottom" />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default NotFound;
