import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../Components/Loading';
import { addAttractions } from '../Redux/actions';
import { attractionsSelector } from '../Redux/selectors';

function WithAttractions(props) {
  const [hasData, setHasData] = useState(false);
  const dispatch = useDispatch();
  const allAttractions = useSelector(attractionsSelector);
  const hasAttractions = Object.values(allAttractions).length > 0;

  useEffect(() => {
    const controller = new AbortController();
    if (hasAttractions) {
      setHasData(true);
    } else {
      fetch('/json/ATTRACTIONS.json', { signal: controller.signal })
        .then((response) => {
          if (!response.ok) throw new Error('HTTP error, status = ' + response.status);
          return response.json();
        })
        .then((json) => {
          addAttractions(dispatch, json);
        })
        .catch((error) => {
          console.error('Error: ' + error.message);
        })
        .finally(() => {
          setHasData(true);
        });
    }

    return () => {
      controller.abort();
    };
  }, [hasAttractions, dispatch]);

  return hasData ? props.children : <Loading />;
}

export default WithAttractions;
