import {
  ADD_CONFIG,
  ADD_FAQDATA,
  ADD_HIWDATA,
  ADD_HOMEDATA,
  ADD_PARK,
  ADD_SAVEDPARK,
  ADD_THEPARKS,
  ADD_ATTRACTIONS,
} from './action-types';

const initialState = {
  Attractions: [],
  config: {},
  FAQ: {},
  HIW: {},
  Home: [],
  Parks: {},
  SavedParks: localStorage.getItem('SavedParks') ? JSON.parse(localStorage.getItem('SavedParks')) : [],
  TheParks: [],
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONFIG:
      return {
        ...state,
        config: action.payload,
      };
    case ADD_ATTRACTIONS:
      return {
        ...state,
        Attractions: action.payload.attractions,
      };
    case ADD_HIWDATA:
      return {
        ...state,
        HIW: action.payload,
      };
    case ADD_HOMEDATA:
      return {
        ...state,
        Home: action.payload,
      };
    case ADD_FAQDATA:
      return {
        ...state,
        FAQ: action.payload,
      };
    case ADD_THEPARKS:
      return {
        ...state,
        TheParks: action.payload,
      };
    case ADD_PARK:
      return {
        ...state,
        Parks: {
          ...state.Parks,
          [action.pid]: action.data,
        },
      };
    case ADD_SAVEDPARK:
      if (state.SavedParks.includes(action.data)) {
        let y = state.SavedParks.filter((x) => x !== action.data);
        localStorage.setItem('SavedParks', JSON.stringify(y));

        return {
          ...state,
          SavedParks: y,
        };
      } else {
        let y = [...state.SavedParks, action.data];
        localStorage.setItem('SavedParks', JSON.stringify(y));

        return {
          ...state,
          SavedParks: y,
        };
      }
    default:
      return state;
  }
};

export default Reducer;
