import React from 'react';
import { Nav } from 'react-bootstrap';

export const HeaderNav = ({ tabConfigurations, setCurrentTab, className = '' }) => (
  <Nav className={`header-nav ${className}`}>
    {tabConfigurations.map(({ tabName, eventKey }) => (
      <Nav.Item key={eventKey}>
        <Nav.Link
          eventKey={eventKey}
          className="text-shadow-white"
          onClick={() => setCurrentTab(eventKey)}
          dangerouslySetInnerHTML={{ __html: tabName }}
        />
      </Nav.Item>
    ))}
  </Nav>
);
