import React, { useEffect, useState } from 'react';
import { Col, Container, Row, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { ControlledAccordion } from '../Components/Common/CustomAccordion';
import { HeaderNav } from '../Components/Common/HeaderNav';
import Icon from '../Components/Common/Icon';
import { LineThrough } from '../Components/Common/LineThrough';
import SavePark from '../Components/Common/SavePark';
import ScrollToTop from '../Components/Common/ScrollToTop';
import { useParkTabConfigurations } from '../Components/Park/useParkTabConfigurations';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import { getConfigSelector, getParkSelector } from '../Redux/selectors';

function Park() {
  const { pid } = useParams();
  const parkData = useSelector(getParkSelector(pid));
  const config = useSelector(getConfigSelector);

  const { width } = useWindowDimensions();
  const isDesktop = width > 992;

  const { ADD1, ADD2, COUNTY, POSTCODE, PARKNAME, PARKDESC, OPERATOR, PARKIMGS } = parkData;

  const folder = pid.substring(0, 1);

  const { tabConfigurations, firstEventKey } = useParkTabConfigurations();

  const formatAddressComponent = (component) =>
    component
      ?.split(',')
      .map((part) => part.trim())
      .join(', ') || '';

  const addressText = [ADD1, ADD2, COUNTY, POSTCODE]
    .filter(Boolean)
    .map((str) => formatAddressComponent(str))
    .join(', ');

  function getCleanParkName(parkName, operator) {
    const cleanedName = parkName.replace(operator, '');
    return cleanedName.length === 0 ? parkName : cleanedName;
  }

  const [activeDesktopTab, setActiveDesktopTab] = useState(firstEventKey);
  const [activeMobileTabs, setActiveMobileTabs] = useState(['1']);

  const accordionItems = tabConfigurations.map(({ tabName, content }) => ({
    title: tabName,
    content,
  }));

  useEffect(() => {
    if (isDesktop) setActiveDesktopTab(firstEventKey);
    if (!isDesktop) setActiveMobileTabs(['0']);
  }, [firstEventKey, isDesktop]);

  return (
    <>
      <Helmet>
        <title>{`${PARKNAME} - Club £${config.promotion} Holidays`}</title>
        <meta name="description" content={`${PARKDESC.replace(/<[^>]*>?/gm, '')}`} />
        <body className="bg-white" />
      </Helmet>
      <ScrollToTop />
      <Container fluid className="bg-light">
        <Container>
          <Row className="align-items-center">
            <Col xs={6} md={2}>
              <Link to="/parks" className="text-decoration-none" title="Go Back">
                <Icon icon="left" variant="dark" size="200" />
              </Link>
            </Col>
            <Col xs={12} md={8} className="py-3 py-md-5 text-center order-last">
              <h1 className="mb-0">{getCleanParkName(PARKNAME, OPERATOR)}</h1>
            </Col>
            <Col xs={6} md={2} className="text-right order-md-last">
              <SavePark pid={pid} showLabel />
            </Col>
          </Row>

          <LineThrough>
            <div className="px-3 px-md-5">
              <span className="font-weight-bold">{OPERATOR}</span>
              <br />
              {addressText}
            </div>
          </LineThrough>

          <Row>
            <Col lg={6}>
              <Slider
                {...{ dots: false, infinite: true, speed: 500, lazyLoad: true }}
                className="gallery-slider mb-5 border-radius"
              >
                {PARKIMGS.map((img, i) => (
                  <img
                    key={i}
                    src={`//content.breakfreeholidays.co.uk/images/parks/${folder}/${pid}/gallery/${pid}-${String(
                      img,
                    ).padStart(2, '0')}.jpg`}
                    alt={PARKNAME}
                    style={{
                      width: '100%',
                      maxHeight: '100%',
                      objectFit: 'cover',
                    }}
                  />
                ))}
              </Slider>
            </Col>
            <Col lg={6} className="pl-lg-5 lead" dangerouslySetInnerHTML={{ __html: PARKDESC }} />
          </Row>
        </Container>
      </Container>

      <TabContainer activeKey={activeDesktopTab}>
        <Container fluid className="p-0 border-bottom-2 bg-light">
          <HeaderNav
            tabConfigurations={tabConfigurations}
            className="d-none d-lg-flex"
            setCurrentTab={setActiveDesktopTab}
          />
        </Container>

        {!isDesktop && (
          <ControlledAccordion
            activeIndices={activeMobileTabs}
            setActiveIndices={setActiveMobileTabs}
            items={accordionItems}
          />
        )}

        {isDesktop && (
          <Container fluid className="bg-white d-flex flex-grow-1">
            <Container className="py-5">
              <TabContent>
                {tabConfigurations.map(({ eventKey, content }) => (
                  <TabPane key={eventKey} eventKey={eventKey}>
                    {content}
                  </TabPane>
                ))}
              </TabContent>
            </Container>
          </Container>
        )}
      </TabContainer>
    </>
  );
}

export default Park;
