import { Col, Row } from 'react-bootstrap';
import { aA, padStr } from '../../Data/exports';
import { useParams } from 'react-router-dom';
import { getParkSelector } from '../../Redux/selectors';
import { useSelector } from 'react-redux';

export const Accommodation = () => {
  const { pid } = useParams();
  const parkData = useSelector(getParkSelector(pid));

  const { ACCOMODATION, PARK_ACCOMODATION_CONTENT } = parkData;

  const accommodation = padStr((ACCOMODATION >>> 0).toString(2), 10).split('');
  const accommodationIndexes = accommodation
    .map((value, index) => (value === '1' ? index : null))
    .filter((index) => index !== null);

  return (
    <Row xs={1} lg={2}>
      <Col
        dangerouslySetInnerHTML={{
          __html: PARK_ACCOMODATION_CONTENT,
        }}
      />
      <Col>
        <Row xs={1} sm={2}>
          {accommodationIndexes.map((i) => (
            <Col key={i} className="d-flex align-items-center pb-2">
              <span className="icon-font size-300 mr-2" data-char={aA[i][0]}>
                {aA[i][0]}
              </span>
              {aA[i][1]}
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};
