import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../Components/Loading';
import { addFaqData } from '../Redux/actions';
import { hasFaqSelector } from '../Redux/selectors';

function WithFaqData(props) {
  const [hasData, setHasData] = useState(false);
  const dispatch = useDispatch();
  const hasFaq = useSelector(hasFaqSelector);

  useEffect(() => {
    const controller = new AbortController();
    if (Object.keys(hasFaq).length === 0) {
      fetch('/json/content/faq.json', { signal: controller.signal })
        .then((response) => {
          if (!response.ok) throw new Error('HTTP error, status = ' + response.status);
          return response.json();
        })
        .then((json) => {
          addFaqData(dispatch, json);
        })
        .catch((error) => {
          console.error('Error: ' + error.message);
        })
        .finally(() => {
          setHasData(true);
        });
    } else {
      setHasData(true);
    }
    return () => {
      controller.abort();
    };
  }, [hasFaq, dispatch]);

  return hasData ? props.children : <Loading />;
}

export default WithFaqData;
