import { Col, Row } from 'react-bootstrap';
import { fA, padStr } from '../../Data/exports';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getParkSelector } from '../../Redux/selectors';

const HIDDEN_FACILITIES = [3, 7, 11, 18, 20];

export const Facilities = () => {
  const { pid } = useParams();
  const parkData = useSelector(getParkSelector(pid));

  const { FACILITIES, PARK_FACILITIES_CONTENT } = parkData;
  const parkFacilitiesBulletPoints = PARK_FACILITIES_CONTENT?.split('|');
  const hasBulletFacilities = parkFacilitiesBulletPoints.length > 0;

  const facilities = padStr((FACILITIES >>> 0).toString(2), 25).split('');
  const facilitiesIndexes = facilities
    .map((value, index) => (value === '1' ? index : null))
    .filter((index) => index !== null && !HIDDEN_FACILITIES.includes(index));
  const hasIconFacilities = facilitiesIndexes.length > 0;

  return (
    <Row xs={1} lg={2}>
      {hasBulletFacilities && (
        <Col className="pb-4">
          <Row xs={1} sm={2} lg={1}>
            {parkFacilitiesBulletPoints.map((facility, i) => (
              <Col key={i}>
                <li dangerouslySetInnerHTML={{ __html: facility }} />
              </Col>
            ))}
          </Row>
        </Col>
      )}
      {hasIconFacilities && (
        <Col>
          <Row xs={1} sm={2}>
            {facilitiesIndexes.map((i) => (
              <Col key={i} className="d-flex align-items-center pb-1">
                <span className="icon-font size-300 mr-2" data-char={fA[i][0]}>
                  {fA[i][0]}
                </span>
                {fA[i][1]}
              </Col>
            ))}
          </Row>
        </Col>
      )}
    </Row>
  );
};
