import { COUNTRY, REGION, OPERATOR, SAVED_PARKS, RESET_FILTERS } from './action-types';

const initialState = {
  country: 'All',
  region: 'All',
  operator: 'All',
  savedParksOnly: false,
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case COUNTRY:
      return {
        ...state,
        country: action.payload,
        region: 'All'
      };
    case REGION:
      return {
        ...state,
        region: action.payload,
      };
    case OPERATOR:
      return {
        ...state,
        operator: action.payload,
      };
    case SAVED_PARKS:
      return {
        ...state,
        savedParksOnly: action.payload,
      };
    case RESET_FILTERS:
      return initialState;
    default:
      return state;
  }
};

export default Reducer;
