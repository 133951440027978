import { Col, Row } from 'react-bootstrap';

export const LineThrough = ({ className = '', children }) => (
  <Row className={`pb-5 text-center ${className}`}>
    <Col className="d-flex align-items-center">
      <hr className="flex-grow-1 border-top" />
      {children}
      <hr className="flex-grow-1 border-top" />
    </Col>
  </Row>
);
