import { combineReducers, createStore } from 'redux';
import reducer from './reducer';
import filtersReducer from './filters/reducer';
import { composeWithDevTools } from 'redux-devtools-extension';

const rootReducer = combineReducers({
  appState: reducer,
  filters: filtersReducer,
});

export const store = createStore(rootReducer, composeWithDevTools());

export default store;
