import { Col, Row } from 'react-bootstrap';
import AttractionCard from '../Common/AttractionCard';
import { useSelector } from 'react-redux';
import { getParkSelector } from '../../Redux/selectors';
import { useParams } from 'react-router-dom';

export const Attractions = () => {
  const { pid } = useParams();

  const parkData = useSelector(getParkSelector(pid));

  const { ATTRACTIONS, PARK_ATTRACTIONS_CONTENT } = parkData;

  return (
    <>
      {ATTRACTIONS.length > 0 && (
        <Row>
          {ATTRACTIONS.map((attraction, i) => (
            <Col key={i} xs={12} md={6} xl={4}>
              <AttractionCard attractionID={attraction} />
            </Col>
          ))}
        </Row>
      )}
      <div dangerouslySetInnerHTML={{ __html: PARK_ATTRACTIONS_CONTENT }} />
    </>
  );
};
