import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getAttractionSelector } from '../../Redux/selectors';

function AttractionCard({ attractionID }) {
  const { a: address, n: name, d: description, u: url } = useSelector(getAttractionSelector(attractionID));
  const imageErrorSrc = '/no-image.png';

  return (
    <Card className="attraction-card box-shadow mb-5">
      <Card.Header>
        <h3 className="h6 m-0">{name}</h3>
        <p className="m-0">{address}</p>
      </Card.Header>
      <Card.Img
        src={`https://content.breakfreeholidays.co.uk/images/attractions/${
          String(attractionID)[0]
        }/${attractionID}/gallery/${attractionID}-01.jpg`}
        alt={name}
        loading="lazy"
        className="rounded-0"
        onError={(e) => ((e.target.onerror = null), (e.target.src = imageErrorSrc))}
      />
      <Card.Body className="attraction-body">
        <p className="m-0">{description}</p>
      </Card.Body>
      <Card.Footer className="p-3">
        <a href={url} className="btn btn-primary btn-block" target="_blank" rel="noopener noreferrer">
          Visit Attraction
        </a>
      </Card.Footer>
    </Card>
  );
}

export default AttractionCard;
