import PropTypes from 'prop-types';

const Icon = ({ icon, className, size, scale, label, variant }) => (
  <>
    <i
      className={`icon-${icon}${className ? ` ${className}` : ''}${size ? ` size-${size}` : ''}${
        scale ? ` scale-${scale}` : ''
      }${variant ? ` text-${variant}` : ''}${label ? ' mr-2' : ''}`}
    />
    {label}
  </>
);

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'dark', 'darker', 'white']),
};

export default Icon;
