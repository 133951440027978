import { useDispatch, useSelector } from 'react-redux';
import { addSavedPark } from '../../Redux/actions';
import Icon from './Icon';
import { getSavedParksSelector } from '../../Redux/selectors';

function SavePark({ pid, showLabel }) {
  const dispatch = useDispatch();
  const savedParks = useSelector(getSavedParksSelector);
  const isThisParkSaved = savedParks.includes(pid);

  return (
    <button
      className={`d-flex w-100 align-items-center justify-content-end save-park${isThisParkSaved ? ' saved' : ''}`}
      onClick={() => addSavedPark(dispatch, pid)}
      aria-label={isThisParkSaved ? 'Remove from Saved Parks' : 'Add to Saved Parks'}
    >
      {showLabel && <small className="mr-2">{isThisParkSaved ? 'Saved' : 'Save Park'}</small>}
      <Icon
        icon={isThisParkSaved ? 'heart-solid' : 'heart'}
        size="150"
        className={`cursor-pointer mr-0${showLabel ? ' d-inline-block' : ''}`}
      />
    </button>
  );
}

export default SavePark;
