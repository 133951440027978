import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../Components/Loading';
import { addTheParks } from '../Redux/actions';
import { theParksSelector } from '../Redux/selectors';

function WithParks(props) {
  const [hasData, setHasData] = useState(false);
  const dispatch = useDispatch();
  const numberOfParks = useSelector(theParksSelector).length;

  useEffect(() => {
    const controller = new AbortController();
    if (numberOfParks === 0) {
      fetch('/json/PARKLIST.json', { signal: controller.signal })
        .then((response) => {
          if (!response.ok) throw new Error('HTTP error, status = ' + response.status);
          return response.json();
        })
        .then((json) => {
          addTheParks(dispatch, json);
        })
        .catch((error) => {
          console.error('Error: ' + error.message);
        })
        .finally(() => {
          setHasData(true);
        });
    } else {
      setHasData(true);
    }
    return () => {
      controller.abort();
    };
  }, [numberOfParks, dispatch]);

  return hasData ? props.children : <Loading />;
}

export default WithParks;
