import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { getConfigSelector } from '../Redux/selectors';

function Attractions() {
  const config = useSelector(getConfigSelector);

  return (
    <>
      <Helmet>
        <title>{`Attractions | Browse Club £${config.promotion} Attractions - The Sun Club £${config.promotion} Holidays`}</title>
      </Helmet>
      <Container className="d-flex flex-column flex-grow-1 ">
        <Row className="mt-auto">
          <Col className="text-left">
            <h1>Attractions</h1>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Attractions;
