import { useParams } from 'react-router-dom';
import NotFound from '../Pages/404';
import { useSelector } from 'react-redux';
import { getParkSelector } from '../Redux/selectors';

export const WithParkFound = ({ children }) => {
  const { pid } = useParams();
  const parkData = useSelector(getParkSelector(pid));

  if (!parkData) return <NotFound />;

  return children;
};
